<template>
  <section class="section">
    <div class="container is-max-desktop has-text-centered">
      <h1 class="title">You have no active subscription</h1>
    </div>

    <div class="container">
      <div
        v-if="!hasActiveSubscription"
        class="notification is-danger is-light has-text-centered m-4"
        style="border-radius: 0"
      >
        <span>
          Your subscription has expired. Please contact your account admin to renew your
          subscription.
        </span>
      </div>
      <div
        v-else-if="hasPastDueSubscription"
        class="notification is-danger is-light has-text-centered m-4"
        style="border-radius: 0"
      >
        <span>
          Your subscription is past due. Please contact your account admin to renew your
          subscription.
        </span>
      </div>
      <!-- list manager data -->
      <div class="list has-text-centered">
        <div class="list-item" v-for="(val, index) in managers" :key="index">
          <div class="list-item-content">
            <div class="list-item-title columns">
              <div class="column is-6">{{ val.first_name }} {{ val.last_name }}</div>
              <div class="column is-6">
                {{ val.email }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import request from '../request';
import { mapGetters } from 'vuex';

export default {
  name: 'Unsubscribed',
  data() {
    return {
      managers: [],
    };
  },
  computed: {
    ...mapGetters(['hasActiveSubscription', 'hasPastDueSubscription']),
  },
  mounted() {
    this.listManagers();
  },
  methods: {
    onManageSubscription() {
      request.post('/subscriptions/portal-session').then((resp) => {
        window.location.href = resp.data.redirect_url;
      });
    },
    listManagers() {
      request.get('/users/managers').then((resp) => {
        console.log(resp.data.managers);
        this.managers = resp.data.managers;
      });
    },
  },
};
</script>
