import axios from 'axios';
import router from './router';
import Vue from 'vue';
import Vuex from 'vuex';
import * as Sentry from '@sentry/vue';

import request from './request.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    environment: process.env.VUE_APP_ENVIRONMENT,
    user: null,
  },
  getters: {
    environment: (state) => state.environment,
    isProd: (state) => state.environment.toLowerCase() === 'production',
    isLoggedIn: (state) => !!state.user,
    isAdmin: (state) => Boolean(state.user && state.user.is_admin),
    isManager: (state) => Boolean(state.user && state.user.is_organization_manager),
    hasCurrentTOS: (state) => state.user && state.user.has_current_tos,
    permReimbursements: (state) => Boolean(state.user && state.user.perm_reimbursements),
    hasActiveSubscription: (state) => state.user && state.user.has_active_subscription,
    hasPastDueSubscription: (state) => state.user && state.user.has_past_due_subscription,
    hasFeatureReimbursement: (state) => state.user && state.user.has_feature_reimbursement,
    hasOverrideReimbursement: (state) => state.user && state.user.has_override_reimbursement,
    organization: (state) => (state.user ? state.user.organization : null),
    user: (state) => state.user,
  },
  mutations: {
    auth_success(state, user) {
      state.user = user;
      Sentry.setUser({ email: user.email, username: user.name });
    },
    auth_logout(state) {
      state.user = null;
      Sentry.configureScope((scope) => scope.setUser(null));
      router.push({ name: 'Login' }).catch(() => {});
    },
  },
  actions: {
    refresh({ commit }) {
      // Use axios instead of refresh so we don't get forced logout by `request` module.
      return axios.get('/api/v1/auth/refresh').then((resp) => {
        const user = resp.data.user;
        commit('auth_success', user);
      });
    },
    logout({ commit }) {
      // Use 'finally' since we don't care if this succeeded or
      // failed. Just send them back to login
      return request.post('/auth/logout').finally(() => {
        commit('auth_logout');
      });
    },
  },
});
